import axios, {AxiosResponse} from "axios";
import {IPlate, IPlatesNames} from "../models/plate";

axios.defaults.baseURL = 'https://demetrio-apiv1.jdemetriodc.com/api/';
//axios.defaults.baseURL = 'https://localhost:5001/api';

const responseBody = (response: AxiosResponse) => response.data;

const responseGetBody = (response: AxiosResponse) => {
    return {
        plates: response.data,
        totalCountRecords: parseInt(response.headers['totalcountrecords'], 10)
    }
};

const request = {
    listed: (url: string, token: string, page: number, recordsPerPage: number) => axios.get(url,{
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            page,
            recordsPerPage
        }
    }).then(responseGetBody),
    get: (url: string, token: string) => axios.get(url,{
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then(responseBody),
    post: (url: string, formData: FormData, token: string) => axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    put: (url: string, formData: FormData, token: string) => axios.put(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    delete: (url: string, token: string) => axios.delete(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody)
};

const Plates = {
    list: (token: string, page: number, recordPerPage: number) : Promise<{ plates: any; totalCountRecords: number }> => request.listed('/plate', token, page, recordPerPage),
    get: (id: number, token: string) => request.get(`/plate/${id}`, token),
    getNames: (token: string) : Promise<IPlatesNames[]> => request.get('/plate/getNames', token),
    create: (plate: FormData, token: string, name: string) => request.post(`/plate/${name}`, plate, token),
    put: (id: number, plate: FormData, token: string, name: string) => request.put(`/plate/${id}/${name}`, plate, token),
    delete: (id: number, token: string, name: string) => request.delete(`/plate/${id}/${name}`, token)
};

export default Plates;
