import axios, {AxiosResponse} from "axios";
import {IUser, IUserForRegister} from "../models/user";

axios.defaults.baseURL = 'https://demetrio-apiv1.jdemetriodc.com/api/';
//axios.defaults.baseURL = 'https://localhost:5001/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string, token: string) => axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    post: (url: string, body: {}, token: string) => axios.post(url, body, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    put: (url: string, body: {}, token: string) => axios.put(url, body, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    patch: (url: string, body: {}, token: string) => axios.patch(url, body, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    delete: (url: string, token: string) => axios.delete(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody)
};

const Users = {
    list: (token: string) : Promise<IUser[]> => request.get('/user', token),
    get: (id: number, token: string) => request.get(`/user/${id}`, token),
    create: (user: IUserForRegister, token: string, name: string) => request.post(`/user/register/${name}`, user, token),
    put: (id: number, user: IUserForRegister, token: string, name: string) => request.put(`/user/${id}/${name}`, user, token),
    patchPassword: (id: number, patchDocument: any, token: string, name: string) => request.patch(`/user/password/${id}/${name}`, patchDocument, token),
    patchUsername: (id: number, patchDocument: any, token: string, name: string) => request.patch(`/user/username/${id}/${name}`, patchDocument, token),
    delete: (id: number, token: string, name: string) => request.delete(`/user/${id}/${name}`, token)
};

export default Users;
