import axios, {AxiosResponse} from "axios";
import {IUserCredentials, IUserLogin} from "../models/user";

axios.defaults.baseURL = 'https://demetrio-apiv1.jdemetriodc.com/api/';
//axios.defaults.baseURL = 'https://localhost:5001/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    login: (url: string, body: IUserCredentials) => axios.post(url, body).then(responseBody),
};

const Auth = {
    login: (userCredentials: IUserCredentials) : Promise<IUserLogin> => request.login('/user/login', userCredentials)
};

export default Auth
