import React, {useContext, useEffect, useRef, useState} from "react";
import LoadingComponent from "../ui/LoadingComponent";
import {Field, Form, Formik} from "formik";
import {IOtherDetail} from "../../models/other";
import other from "../../api/other";
import {AuthContext} from "../../context/AuthContext";
import JoditEditor from "jodit-react";
import { useHistory } from "react-router-dom";


interface IOtherFormScreen {
    showNotification?: any,
    messageNotification?: any,
    showSidebar: boolean,
    setShowSidebar: any
}

const OtherFormScreen = (props: IOtherFormScreen) => {
    const labelStyles = "font-bold mb-2";
    const inputStyles = "border-1 border-primary rounded-lg p-1 mb-2";

    const initialState: IOtherDetail = {
        teamDescription: '',
        phoneNumber: '',
        urlForm: ''
    };

    const { user, userDispatch } = useContext(AuthContext);

    const editor = useRef(null);
    const config = {
        width: "600px",
        height: "450px"
    };

    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [otherState, setOtherState] = useState<IOtherDetail>(initialState);

    function onChangeShow() {
        if (props.showSidebar)
            props.setShowSidebar(false);
        else
            props.setShowSidebar(true)
    }

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    useEffect(() => {
        setLoading(true);
        other.get()
            .then((response) => setOtherState(response))
            .catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false))
    }, []);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in ' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>
            <div className={(loading) ? "hidden" : "block md:py-4 md:px-20 w-full h-full"}>
                <div className={"flex flex-row justify-between w-full"}>
                    <button className={"bg-white border-1 border-primary rounded-lg lg:hidden py-2 px-4"} onClick={onChangeShow}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={"w-4 h-4"} viewBox="0 0 38 26.333">
                            <g id="Icon_feather-menu" data-name="Icon feather-menu" transform="translate(-3 -7.5)">
                                <path id="Trazado_115" data-name="Trazado 115" d="M4.5,18h35" transform="translate(0 2.667)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_116" data-name="Trazado 116" d="M4.5,9h35" transform="translate(0)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                                <path id="Trazado_117" data-name="Trazado 117" d="M4.5,27h35" transform="translate(0 5.333)" fill="none" stroke="#6B0DBC" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                            </g>
                        </svg>
                    </button>
                </div>
                <div className={"w-full h-full overflow-y-scroll p-2"}>
                    <div className={"mt-4 flex flex-col p-8 w-full lg:w-3/4 2xl:w-1/2 shadow-custom rounded-lg animate-fade-in"}>
                        <div className={"flex flex-row w-full items-center"}>
                            <p className={"font-bold text-primary text-lg"}>Otros aspectos de la web</p>
                        </div>

                        <Formik enableReinitialize={true} initialValues={otherState} onSubmit={async values => {
                            setLoading(true);

                            other.put(values, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
                            + user?.user?.userResponse?.lastName as string).then(() => {
                                setLoading(false);
                                props.messageNotification('Se ha actualizado correctamente');
                                props.showNotification(true);
                            }).catch((error) => checkError(error.toString()))
                                .finally(() => setLoading(false))
                        }}>
                            {(formikProps) => (
                                <Form className={"mt-4"}>
                                    <div className={"flex flex-col mb-2"}>
                                        <label htmlFor={"phoneNumber"} className={labelStyles}>Número de teléfono*</label>
                                        <Field type={"tel"} name={"phoneNumber"} className={inputStyles} />
                                    </div>
                                    <div className={"flex flex-col mb-2"}>
                                        <label htmlFor={"urlForm"} className={labelStyles}>URL del formulario*</label>
                                        <Field type={"text"} name={"urlForm"} className={inputStyles} />
                                    </div>

                                    <div className={"flex flex-col"}>
                                        <label htmlFor={"teamDescription"} className={labelStyles}>Descripción del equipo*</label>
                                        <JoditEditor
                                            ref={editor}
                                            value={formikProps.values.teamDescription}
                                            config={config}
                                            onChange={(newContent: string) => {formikProps.values.teamDescription = newContent}}
                                        />
                                    </div>

                                    <input type="submit" value={"Guardar"} className={"bg-primary text-white p-2 rounded-lg mt-2 cursor-pointer"} />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    )
};

export default OtherFormScreen;
