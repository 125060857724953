import React, {useContext, useEffect, useReducer, useState} from 'react';
import {IReservation} from "../../models/reservation";
import reservation from "../../api/reservation";
import LoadingComponent from "../ui/LoadingComponent";
import ModalComponent from "../ui/ModalComponent";
import {reservationReducer} from "../../reducers/reservationReducer";
import { useHistory } from 'react-router-dom';
import {AuthContext} from "../../context/AuthContext";

interface IReservationListComponent {
    dayId: any,
    maxPeople: any,
    showNotification?: any,
    messageNotification?: any
}

const ReservationListComponent = (props: IReservationListComponent) => {
    const buttonStyles = "h-6 border-1 border-secondary rounded-full mt-2 w-full text-primary text-sm transition duration-300 ease-in-out hover:bg-primary-hover hover:text-white";
    const buttonStyleActive = "h-6 border-1 border-secondary rounded-full mt-2 w-full text-sm bg-primary-hover text-white";
    const initialState: IReservation[] = [];

    const reservationRequestPatch: any = [{
        "op": "replace",
        "path": "/isConfirmed",
        "value": "true"
    }];

    const history = useHistory();
    const { user, userDispatch } = useContext(AuthContext);

    let peopleCounter = 0;

    const [state, dispatch] = useReducer(reservationReducer, initialState);
    const [loading, setLoading] = useState(false);

    const [confirm, setConfirm] = useState({
        show: false,
        itemId: ''
    });

    function checkError(error: string) {
        if (error.includes('500')) {
            props.messageNotification('Error interno del servidor, comprueba internet y vuelve a intentarlo luego');
            props.showNotification(true);
        } else if (error.includes('404')) {
            props.messageNotification('El dato que quiere borrar no existe');
            props.showNotification(true);
        } else if (error.includes('400')) {
            props.messageNotification('La operación ha salido mal');
            props.showNotification(true);
        } else if (error.includes('Network Error')) {
            userDispatch({
                user: null,
            });
            history.replace('/login')
        } else {
            props.messageNotification('Error desconocido');
            props.showNotification(true);
        }
    }

    const loadList = () => {
        setLoading(true);
        reservation.list(props.dayId, user?.user?.token as string)
            .then(response => {
                const action = {
                    type: 'charge',
                    payload: response
                };

                dispatch(action);
            }).catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false));
    };

    const onDelete = async (id: string) => {
        setLoading(true);
        reservation.delete(id, props.dayId, user?.user?.userResponse?.name as string + ' '
        + user?.user?.userResponse?.lastName as string)
            .then(() => {
                props.messageNotification('Se ha borrado correctamente');
                props.showNotification(true);

                setLoading(true);
                loadList()
            })
            .catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false));
    };

    const onConfirmReservation = (id: string) => {
        setLoading(true);
        reservation.confirm(id, reservationRequestPatch, user?.user?.token as string, user?.user?.userResponse?.name as string + ' '
        + user?.user?.userResponse?.lastName as string)
            .then(() => {
                props.showNotification(true);
                props.messageNotification("Reserva confirmada");

                loadList()
            }).catch((error) => checkError(error.toString()))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadList()
    }, []);

    return (
        <>
            <div className={(loading) ? 'block w-full h-full flex flex-col justify-center items-center animate-fade-in' : 'hidden'}>
                <div className={"w-40 h-40"}>
                    <LoadingComponent />
                </div>
            </div>

            <div className={ (loading) ? "hidden" : "block h-full w-full overflow-y-scroll px-2"}>
                <div className={"mt-4 flex flex-row w-full flex-wrap gap-1.5 lg:gap-2 items-center animate-fade-in"}>
                    {state.map((value: any) => (
                        value.map((trueValue: IReservation) => {
                            peopleCounter = peopleCounter + trueValue.numberOfPeople;

                            return (
                                <div key={trueValue.id} className={"flex flex-col p-4 w-full md:w-auto shadow-custom rounded-lg"}>
                                    <div className={"flex flex-row items-center"}>
                                        <div className={"h-16 w-16 p-4 mr-2 flex flex-row items-center"}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.78 42.78">
                                                <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M21.39,24.064A12.032,12.032,0,1,0,9.358,12.032,12.035,12.035,0,0,0,21.39,24.064Zm10.7,2.674h-4.6a14.545,14.545,0,0,1-12.182,0H10.7A10.694,10.694,0,0,0,0,37.433V38.77A4.012,4.012,0,0,0,4.011,42.78H38.77A4.012,4.012,0,0,0,42.78,38.77V37.433A10.694,10.694,0,0,0,32.085,26.738Z" fill="#6B0DBC"/>
                                            </svg>
                                        </div>
                                        <div className={"flex flex-col"}>
                                            <p className={"font-bold text-base"}>{trueValue.name}</p>
                                            <p className={"font-bold text-base"}>{trueValue.surname}</p>
                                        </div>
                                    </div>

                                    <p><span className={"text-primary"}>Número de personas: </span>{trueValue.numberOfPeople}</p>
                                    <p><span className={"text-primary"}>Teléfono: </span>{trueValue.telefone}</p>
                                    <p className={"w-52"}><span className={"text-primary"}>Alérgenos: </span>{trueValue.allergens}</p>

                                    <hr className={(user?.user?.userResponse?.role === 'alumno') ? "hidden" : "block"} />
                                    <div className={(user?.user?.userResponse?.role === 'alumno') ? "hidden" : "block"}>
                                        <button className={(trueValue.isConfirmed) ? buttonStyleActive : buttonStyles}
                                                disabled={trueValue.isConfirmed}
                                                onClick={() => onConfirmReservation(trueValue.id)}>
                                            Confirmar
                                        </button>
                                        <button className={buttonStyles}
                                                onClick={() => history.push(`/day/reservations/update/${trueValue.id}&${props.dayId}`)}>Actualizar</button>
                                        <button className={buttonStyles} onClick={() => {
                                            setConfirm({
                                                show: true,
                                                itemId: trueValue.id
                                            });
                                        }}>Borrar</button>
                                    </div>
                                </div>
                            )
                        })
                    ))}

                    <button className={(peopleCounter >= props.maxPeople || user?.user?.userResponse?.role === 'alumno') ? "hidden" : "rounded-full bg-primary transition duration-300 ease-in-out hover:bg-primary-hover h-16 w-full md:w-16"}
                        onClick={() => history.push(`/day/reservations/create/${props.dayId}`)}>
                        <div className={"w-8 h-8 m-auto p-1"}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.5 31.5">
                                <path id="Icon_awesome-plus" data-name="Icon awesome-plus" d="M29.25,14.625H19.125V4.5a2.25,2.25,0,0,0-2.25-2.25h-2.25a2.25,2.25,0,0,0-2.25,2.25V14.625H2.25A2.25,2.25,0,0,0,0,16.875v2.25a2.25,2.25,0,0,0,2.25,2.25H12.375V31.5a2.25,2.25,0,0,0,2.25,2.25h2.25a2.25,2.25,0,0,0,2.25-2.25V21.375H29.25a2.25,2.25,0,0,0,2.25-2.25v-2.25A2.25,2.25,0,0,0,29.25,14.625Z" transform="translate(0 -2.25)" fill="#fff"/>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>

            <ModalComponent state={confirm} setState={setConfirm} onConfirm={onDelete}/>
        </>
    );
};

export default ReservationListComponent;
