import axios, {AxiosResponse} from "axios";
import {IOtherDetail} from "../models/other";

axios.defaults.baseURL = 'https://demetrio-apiv1.jdemetriodc.com/api/';
//axios.defaults.baseURL = 'https://localhost:5001/api';

const responseBody = (response: AxiosResponse) => response.data;

const request = {
    get: (url: string) => axios.get(url).then(responseBody),
    put: (url: string, body: IOtherDetail, token: string) => axios.put(url, body, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(responseBody),
    putForm: (url: string, formData: FormData, token: string) => axios.put(url, formData, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    }).then(responseBody)
};

const Other = {
    get: (): Promise<IOtherDetail> => request.get('/other'),
    put: (other: IOtherDetail, token: string, name: string) => request.put(`/other/${name}`, other, token),
    putImage: (otherImage: FormData, token: string, name: string) => request.putForm(`/other/team/${name}`, otherImage, token)
};

export default Other;
