import axios, {AxiosResponse} from "axios";

axios.defaults.baseURL = 'https://demetrio-apiv1.jdemetriodc.com/api/';
//axios.defaults.baseURL = 'https://localhost:44316/api';

const responseBody = (response: AxiosResponse) => response.data;

const responsePaginateBody = (response: AxiosResponse) => {
    return {
        log: response.data,
        totalCountRecords: parseInt(response.headers['totalcountrecords'], 10)
    }
};

const request = {
    listed: (url: string, token: string, page: number, recordsPerPage: number) => axios.get(url, {
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            page,
            recordsPerPage
        }
    }).then(responsePaginateBody)
};

const Logs = {
    list: (token: string, page: number, recordsPerPage: number) : Promise<{log: any, totalCountRecords: number}> => request.listed('/log', token, page, recordsPerPage)
};

export default Logs;
